// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
let currentPage = null;

const makeEl = function(html) {
    const template = document.createElement('template');

    template.innerHTML = html.trim();

    return template.content.firstChild;
};

const switchFormPage = (pages, page) => {
    Array.prototype.forEach.call(pages, (p) => {
        if (!p.classList.contains('hidden')) {
            p.classList.add('hidden');
        }
    });

    page.classList.remove('hidden');
    currentPage = page;
};

const validateSpecial = (input, regexes) => {
    const value = input.value;

    for (const regex of regexes) {
        console.log('tegex');
        console.log(regex);
        if (regex.test(value)) {
            console.log('HE MATCH');
            return true;
        }
        console.log('he aint match');
    }

    return false;
};

const validateFormFields = (form, inputs) => {
    let valid = true;

    for (const input of inputs) {
        if (!(valid ? input.reportValidity() : input.checkValidity())) {
            input.classList.add('is-invalid');
            valid = false;
        } else {
            input.classList.remove('is-invalid');
        }
    }

    return valid;
};

const setupVolunteerApplications = () => {
    const formPages = document.querySelectorAll('.form-page');

    if (!formPages || !formPages.length) {
        return;
    }

    const form = formPages[0].closest('form');

    switchFormPage(formPages, formPages[0]);

    Array.prototype.forEach.call(formPages, (p, i) => {
        const footer = p.querySelector('.form-page__footer');

        if (i !== formPages.length - 1) {
            const nextButton = makeEl('<button type="button" class="btn btn-primary">Next Page</button>');

            nextButton.addEventListener('click', () => {
                if (validateFormFields(form, p.querySelectorAll('input'))) {
                    switchFormPage(formPages, formPages[i + 1]);
                }
            });

            footer.prepend(nextButton);
        }

        if (i !== 0) {
            const prevButton = makeEl('<button type="button" class="btn btn-secondary">Previous Page</button>');
            prevButton.addEventListener('click', () => {
                switchFormPage(formPages, formPages[i - 1]);
            });
            footer.prepend(prevButton);
        }
    });

    form.addEventListener('submit', e => {
        if (currentPage !== formPages[formPages.length - 1]) {
            e.preventDefault();
            currentPage.querySelector('button.btn-primary').click();
        }
    });

    [...document.querySelectorAll('[data-validate-special]')].forEach(elem => {
        const regexes = elem.dataset.validateSpecial
            .split('|')
            .map(v => new RegExp(v, 'i'));
        elem.addEventListener('keyup', e => {
            if (validateSpecial(elem, regexes)) {
                elem.classList.remove('is-invalid');
                elem.classList.add('is-valid');
            } else {
                elem.classList.remove('is-valid');
                elem.classList.add('is-invalid');
            }
        });
    });
};

const setupFriendlyTimes = () => {
    const times = document.querySelectorAll('time');

    Array.prototype.forEach.call(times, elem => {
       const date = new Date(elem.getAttribute('datetime'));

       elem.innerText = date.toLocaleString();
    });
};

const setupSearch = () => {
    const searchField = document.querySelector('#search');
    const searchTable = document.querySelector('#search-table');

    if (!searchField || !searchTable) {
        return;
    }

    searchField.addEventListener('keyup', e => {
        let searchText = e.target.value;
        const searchableRows = searchTable.querySelector('tbody').childNodes;
        Array.prototype.forEach.call(searchableRows, rowNode => {
            if (rowNode.classList.contains('hidden')) {
                rowNode.classList.remove('hidden');
            }
        });

        if (searchText && searchText !== '') {
            searchText = searchText.trim().toLowerCase();
            Array.prototype.forEach.call(searchableRows, rowNode => {
                const rowName = rowNode.querySelector('.js-row-search').innerText.toLowerCase();

                if (rowName.indexOf(searchText) === -1) {
                    rowNode.classList.add('hidden');
                }
            })
        }
    });
};

const setupPage = () => {
    setupVolunteerApplications();
    setupFriendlyTimes();
    setupSearch();
};

if (document.readyState !== 'loading') {
    setupPage();
} else {
    document.addEventListener('DOMContentLoaded', setupPage);
}
